<template>
  <svg width="30px" height="30px" viewBox="0 0 50 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <radialGradient cx="-6963.62087%" cy="-3394.56%" fx="-6963.62087%" fy="-3394.56%" r="6078.17%" id="radialGradient-1">
        <stop stop-color="#64B0E2" offset="49.84%"></stop>
        <stop stop-color="#67B5E7" offset="71.7%"></stop>
        <stop stop-color="#6EC2F5" offset="97.06%"></stop>
        <stop stop-color="#6FC4F7" offset="100%"></stop>
      </radialGradient>
      <radialGradient cx="-7609.74385%" cy="-3019.06%" fx="-7609.74385%" fy="-3019.06%" r="6078.17%" id="radialGradient-2">
        <stop stop-color="#64B0E2" offset="49.84%"></stop>
        <stop stop-color="#67B5E7" offset="71.7%"></stop>
        <stop stop-color="#6EC2F5" offset="97.06%"></stop>
        <stop stop-color="#6FC4F7" offset="100%"></stop>
      </radialGradient>
    </defs>
    <g id="Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Главная-XL" transform="translate(-102.000000, -18.000000)" fill-rule="nonzero">
        <g id="Group" transform="translate(102.000000, 18.000000)">
          <g transform="translate(28.165429, 10.858961)" fill="#222222" id="Path">
            <path d="M4.03817603,16.5633086 L4.03817603,16.8144221 C4.02460233,16.7227996 4.02799576,16.6379639 4.03817603,16.5633086 Z"></path>
            <path d="M19.0574761,12.6506893 L19.0574761,24.2901379 C19.0574761,24.761824 18.6740191,25.145281 18.1989396,25.145281 L6.56288441,25.145281 C4.17730647,25.145281 1.94782609,24.4767762 0.0509013786,23.3196182 C1.86299046,21.5278897 3.24750795,19.3051962 4.03817603,16.8144221 C4.10604454,16.6108165 4.16712619,16.4038176 4.221421,16.1968187 C4.62863203,15.7624602 5.58897137,15.6742312 6.22354189,15.5079533 C6.22354189,15.5283139 6.22693531,15.5486744 6.23032874,15.5724284 L6.23032874,15.5079533 C6.70201485,15.3790032 6.98706257,15.2093319 6.73255567,14.819088 C5.82311771,13.4277837 5.12746554,11.5444327 4.67274655,10.0615058 C4.28589608,6.81399788 2.92513256,3.86511135 0.885683987,1.51686108 C2.58918346,0.644750795 4.52004242,0.156097561 6.56288441,0.156097561 C13.4617179,0.156097561 19.0574761,5.75185578 19.0574761,12.6506893 Z"></path>
            <path d="M6.23032874,15.321315 L6.23032874,15.5724284 C6.22693531,15.5486744 6.22354189,15.5283139 6.22354189,15.5079533 C6.22014846,15.4400848 6.22354189,15.3790032 6.23032874,15.321315 Z"></path>
          </g>
          <g transform="translate(0.678685, 7.126193)" id="Path">
            <path d="M31.5249205,20.2960764 L31.5249205,20.5471898 C31.5113468,20.4555673 31.5147402,20.3707317 31.5249205,20.2960764 Z" fill="url(#radialGradient-1)"></path>
            <path d="M33.7170732,19.2407211 L33.7170732,19.0540827 C33.7102863,19.1117709 33.7068929,19.1728526 33.7102863,19.2407211 C33.0757158,19.4069989 32.1153765,19.495228 31.7081654,19.9295864 C31.6538706,20.1365854 31.592789,20.3435843 31.5249205,20.5471898 L31.5249205,20.2960764 C31.5147402,20.3707317 31.5113468,20.4555673 31.5249205,20.5471898 C30.7342524,23.0379639 29.3497349,25.2606575 27.5376458,27.052386 C24.6498409,29.9164369 20.6795334,31.681018 16.2986214,31.681018 L1.41845175,31.681018 C0.814422057,31.681018 0.325768823,31.1889714 0.325768823,30.5815483 L0.325768823,20.9102863 C2.53488865,22.2846235 5.14103924,23.078685 7.93043478,23.078685 C15.8812301,23.078685 22.3253446,16.6345705 22.3253446,8.68377519 C22.3253446,5.43966066 21.2496288,2.4466596 19.4375398,0.0407211029 C22.9802757,0.746553552 26.0988335,2.62311771 28.3724284,5.24962884 C30.411877,7.59787911 31.7726405,10.5467656 32.159491,13.7942736 C32.61421,15.2772004 33.3098621,17.1605514 34.2193001,18.5518558 C34.473807,18.9420997 34.1887593,19.1117709 33.7170732,19.2407211 Z" fill="#2EB2F4"></path>
            <path d="M33.7170732,19.0540827 L33.7170732,19.2407211 C33.7136797,19.2373277 33.7136797,19.2407211 33.7102863,19.2407211 C33.7068929,19.1728526 33.7102863,19.1117709 33.7170732,19.0540827 Z" fill="url(#radialGradient-2)"></path>
          </g>
          <path d="M34.3957582,26.1802757 L34.3957582,26.4313892 C34.3923648,26.4076352 34.3889714,26.3872747 34.3889714,26.3669141 C34.3855779,26.2990456 34.3889714,26.2379639 34.3957582,26.1802757 Z" id="Path" fill="#4AC0F4"></path>
          <path d="M17.452386,2.91834571 C17.452386,3.38663839 17.3336161,3.82439024 17.1198303,4.20445387 C16.6685048,5.02226935 15.7963945,5.57879109 14.795334,5.57879109 C13.3259809,5.57879109 12.1348887,4.38769883 12.1348887,2.91834571 C12.1348887,2.55864263 12.2061506,2.21590668 12.3351007,1.90710498 C12.7321315,0.939978791 13.685684,0.257900318 14.795334,0.257900318 C16.2612937,0.257900318 17.452386,1.44899258 17.452386,2.91834571 Z" id="Path" fill="#2EB2F4"></path>
          <path d="M17.0248144,15.8099682 C17.0248144,20.4589608 13.2581124,24.2256628 8.60911983,24.2256628 C5.45662778,24.2256628 2.70795334,22.4916225 1.26914104,19.926193 C0.583669141,18.7079533 0.193425239,17.3064687 0.193425239,15.8099682 C0.193425239,11.164369 3.96352068,7.39766702 8.60911983,7.39766702 C9.58303287,7.39766702 10.5162248,7.56394486 11.3849417,7.8659597 C14.6697773,9.01293743 17.0248144,12.1348887 17.0248144,15.8099682 Z" id="Path" fill="#2EB2F4"></path>
        </g>
      </g>
    </g>
  </svg>
</template>
<script setup lang="ts">
</script>
